import React from "react"
import Navbar from "./Navbar"
import "../styles/global.css"
import Footer from "./Footer"
import { createTheme, ThemeProvider, Container, Box } from "@material-ui/core"
import { green, purple } from "@material-ui/core/colors"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fefefe",
    },
    secondary: {
      main: green[500],
    },
  },
  typography: {
    fontFamily: "Quicksand",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
})

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Navbar />
        <Box m={3}>{children}</Box>
        <Footer />
      </Container>
    </ThemeProvider>
  )
}
