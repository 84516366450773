import React from "react"
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  Link,
  AppBar,
  Toolbar,
} from "@material-ui/core"
import gray from "@material-ui/core/colors"

const useStyles = makeStyles(theme => ({
  houseIcon: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  link: {
    color: "black",
  },
  footer: {
    marginTop: "calc(15% + 20px)",
    bottom: "0",
  },
  source: {
    fontSize: "0.8em",
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            <Typography className={classes.title} variant="p">
              <Link className={classes.link} to="/properties">
                Terms
              </Link>
            </Typography>
            <Typography className={classes.title} variant="p">
              <Link className={classes.link} to="/properties">
                Privacy
              </Link>
            </Typography>
          </Grid>
        </Toolbar>
        <Typography variant="body2" color={gray} className={classes.source}>
          THE SOURCE OF THE DISPLAYED DATA IS EITHER THE PROPERTY OWNER OR
          PUBLIC RECORD PROVIDED BY NON-GOVERNMENTAL THIRD PARTIES. IT IS
          BELIEVED TO BE RELIABLE BUT NOT GUARANTEED. THIS INFORMATION IS
          PROVIDED EXCLUSIVELY FOR CONSUMERS’ PERSONAL, NON-COMMERCIAL USE.
        </Typography>
      </AppBar>
    </footer>
  )
}
